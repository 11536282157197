import css from "../../css/header/header.module.css";
import cssMenu from "../../css/header/menu.module.css";

import Menu from "./Menu";
import AccountIcon from "mdi-react/AccountIcon";

import React from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useAuth } from "../common/Auth";

const AuthModal = dynamic(() => import("../auth/AuthModal"), { ssr: false });

const HeaderIndex = (props) => {
    const { profile, loaded, isAuthenticated } = useAuth();

    const { color } = props;
    const showLoginBtn = !parseInt(process.env.DISABLE_LOGIN);
    let myAccountPath = "/user";

    if (isAuthenticated && profile.permissions.includes("agent-leads.list")) {
        myAccountPath = "/user/opportunities";
    }

    return (
        <>
            <div className={css.header}>
                <div className={css.menu}>
                    <Menu theme={cssMenu} color={color} burgerIcon={color} />
                </div>
                {loaded && showLoginBtn && (
                    <div className={css.auth}>
                        {isAuthenticated && (
                            <a href={myAccountPath} className={css.loginBtn}>
                                <AccountIcon /> My Account
                            </a>
                        )}

                        {!isAuthenticated && (
                            <AuthModal className={css.loginBtn}>
                                Login or Sign Up
                            </AuthModal>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

HeaderIndex.propTypes = {
    color: PropTypes.string,
};

HeaderIndex.defaultProps = {
    color: "red",
};

export default HeaderIndex;
