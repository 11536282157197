import LayoutIndex from "../components/LayoutIndex";
import css from "../css/index/block.module.css";
import cssWide from "../css/index/block-wide.module.css";

import Logo from "../components/header/Logo";
import Blog from "../components/index/Blog";

import Jumbotron from "../components/index/Jumbotron";
import FeaturedListings from "../components/index/FeaturedListings";
import Neighborhoods from "../components/index/Neighborhoods";

import React from "react";
import { NextSeo } from "next-seo";
import Head from "next/head";
import classNames from "classnames";
import requestIp from "request-ip";
import { getSeoIndex } from "../service/seo";
import axiosResiInstance from "../http/axiosResi";
import { fetchGepIp } from "../service/cache";
import { CATEGORY_SALE, CATEGORY_RENT } from "../service/search";

const DEFAULT_CITY = {
    id: "CA-LOS-ANGELES",
    name: "Los Angeles",
    url: "CA/Los_Angeles",
    state: {
        id: "CA",
        abbr: "CA",
        url: "CA",
        name: "California",
    },
    label: "Los Angeles, CA",
};

const fetchCityAndListings = async (ip, category) => {
    let city = DEFAULT_CITY;
    let listings = [];

    try {
        const location = await fetchGepIp(ip);

        if (location.status === 200 && location.data && location.data.city) {
            city = location.data.city;
        }
    } catch (e) { }

    try {
        const response = await axiosResiInstance.get(`/listings`, {
            params: {
                location_id: city.id,
                category: category === CATEGORY_SALE ? "PURCHASE" : "RENT",
                with_photos_only: "1",
                limit: 6,
                format: "short_with_photos",
                sort: "-listed_date",
            },
        });

        if (response && response.data && response.data.data) {
            listings = response.data.data;
        }
    } catch (e) { }

    return { city, listings };
};

const Index = (props) => {
    const { category, city, listings } = props;

    const titleClass = classNames({
        [css.title]: category === CATEGORY_SALE,
        [css.titleBlue]: category === CATEGORY_RENT,
    });

    const SEO = getSeoIndex(category);
    const h1 =
        "Real Estate For " +
        (category === CATEGORY_SALE ? "Sale" : "Rent") +
        " in ";

    return (
        <>
            <Head>
                <link rel="preconnect" href={process.env.RESI_API_URL} />
            </Head>
            <LayoutIndex>
                <NextSeo {...SEO} />
                <Jumbotron category={category} />
                <div className={cssWide.blockWhite}>
                    <h1 className={titleClass}>
                        {h1}
                        {city !== null && (
                            <span className={css.titleSelected}>
                                {city.label}
                            </span>
                        )}
                    </h1>
                    <div className={css.content}>
                        {city !== null && listings !== null && (
                            <FeaturedListings
                                city={city}
                                listings={listings}
                                category={category}
                            />
                        )}
                    </div>
                </div>

                <div className={cssWide.blockWhite}>
                    <h2 className={titleClass}>
                        Hot! Investworthy{" "}
                        <span className={css.titleSelected}>Neighborhoods</span>
                    </h2>
                    <div className={css.content}>
                        <Neighborhoods />
                    </div>
                </div>
                <div className={css.block}>
                    <h2 className={titleClass}>
                        <Logo size="lg" />{" "}
                        <span className={css.titleSelected}>Magazine</span>
                    </h2>
                    <div className={css.content}>
                        <Blog />
                    </div>
                </div>
            </LayoutIndex>
        </>
    );
};

Index.getInitialProps = async ({ query, req }) => {
    const ip = req ? requestIp.getClientIp(req) : null;
    const category = query.category || CATEGORY_SALE;

    const r = await fetchCityAndListings(ip, category);

    return {
        category,
        city: r.city,
        listings: r.listings,
    };
};

export default Index;
