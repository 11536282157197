import css from "../../css/index/blog.module.css";

import sanDiegoImg from "../../static/img/magazine/san_diego.jpg";
import denverImg from "../../static/img/magazine/denver.jpg";
import austinImg from "../../static/img/magazine/austin.jpg";
import vegasImg from "../../static/img/magazine/vegas2.jpg";

import Image from "next/image";

const Blog = () => (
    <div className={css.blog}>
        <div className={css.post}>
            <div className={css.img}>
                <Image
                    src={sanDiegoImg}
                    width="264"
                    height="220"
                    alt="Magazine - San Diego, CA"
                />
            </div>
            <div className={css.footer}>
                <div className={css.title}>San Diego, CA</div>
                <div className={css.date}>
                    Coming {new Date().getFullYear()}
                </div>
            </div>
        </div>
        <div className={css.post}>
            <div className={css.img}>
                <Image
                    src={denverImg}
                    width="264"
                    height="220"
                    alt="Magazine - Denver, CO"
                />
            </div>
            <div className={css.footer}>
                <div className={css.title}>Denver, CO</div>
                <div className={css.date}>
                    Coming {new Date().getFullYear()}
                </div>
            </div>
        </div>
        <div className={css.post}>
            <div className={css.img}>
                <Image
                    src={austinImg}
                    width="264"
                    height="220"
                    alt="Magazine - Austin, TX"
                />
            </div>
            <div className={css.footer}>
                <div className={css.title}>Austin, TX</div>
                <div className={css.date}>
                    Coming {new Date().getFullYear()}
                </div>
            </div>
        </div>
        <div className={css.post}>
            <div className={css.img}>
                <Image
                    src={vegasImg}
                    width="264"
                    height="220"
                    alt="Magazine - Las Vegas, NV"
                />
            </div>
            <div className={css.footer}>
                <div className={css.title}>Las Vegas, NV</div>
                <div className={css.date}>
                    Coming {new Date().getFullYear()}
                </div>
            </div>
        </div>
    </div>
);

export default Blog;
