import css from "../css/layout/index.module.css";

import Footer from "./footer/Footer";
import React from "react";

const LayoutIndex = (props) => {
    return (
        <div className={css.page}>
            <div className={css.wrapper}>{props.children}</div>
            <Footer />
        </div>
    );
};

export default LayoutIndex;
