import css from "../../css/index/neighborhoods.module.css";

import bayTerraceMap from "../../static/img/hoods/bay-terrace.jpg";
import southdaleMap from "../../static/img/hoods/southdale.jpg";
import summerlinMap from "../../static/img/hoods/summerlin.jpg";
import windsorParkMap from "../../static/img/hoods/windsor_park.jpg";
import collegeParkMap from "../../static/img/hoods/college_park.jpg";

import Link from "next/link";
import Image from "next/image";

const Neighborhoods = () => (
    <div className={css.neighborhoods}>
        <Link
            href="/search?location=92139&category=sale"
            as="/homesearch/for_sale/CA/San_Diego/92139"
            className={css.item}
        >
            <div className={css.img}>
                <Image
                    src={bayTerraceMap}
                    sizes=""
                    alt="Investworthy Neighborhoods - Bay Terrace, San Diego, CA"
                />
            </div>
            <div className={css.footer}>
                <div className={css.name}>
                    <div className={css.nameContent}>Bay Terrace</div>
                    <div className={css.state}>San Diego, CA</div>
                </div>
                <div className={css.listings}>
                    19
                    <br />
                    Homes
                </div>
            </div>
        </Link>
        <Link
            href="/search?location=46217&category=sale"
            as="/homesearch/for_sale/IN/Indianapolis/46217"
            className={css.item}
        >
            <div className={css.img}>
                <Image
                    src={southdaleMap}
                    sizes=""
                    alt="Investworthy Neighborhoods - Southdale, Indianapolis, IN"
                />
            </div>
            <div className={css.footer}>
                <div className={css.name}>
                    <div className={css.nameContent}>Southdale</div>
                    <div className={css.state}>Indianapolis, IN</div>
                </div>
                <div className={css.listings}>
                    47
                    <br />
                    Homes
                </div>
            </div>
        </Link>
        <Link
            href="/search?location=89135&category=sale"
            as="/homesearch/for_sale/NV/Las_Vegas/89135"
            className={css.item}
        >
            <div className={css.img}>
                <Image
                    src={summerlinMap}
                    sizes=""
                    alt="Investworthy Neighborhoods - Summerlin, Las Vegas, NV"
                />
            </div>
            <div className={css.footer}>
                <div className={css.name}>
                    <div className={css.nameContent}>Summerlin South</div>
                    <div className={css.state}>Las Vegas, NV</div>
                </div>
                <div className={css.listings}>
                    111
                    <br />
                    Homes
                </div>
            </div>
        </Link>
        <Link
            href="/search?location=28205&category=sale"
            as="/homesearch/for_sale/NC/Charlotte/28205"
            className={css.item}
        >
            <div className={css.img}>
                <Image
                    src={windsorParkMap}
                    sizes=""
                    alt="Investworthy Neighborhoods - Windsor Park, Charlotte, NC"
                />
            </div>
            <div className={css.footer}>
                <div className={css.name}>
                    <div className={css.nameContent}>Windsor Park</div>
                    <div className={css.state}>Charlotte, NC</div>
                </div>
                <div className={css.listings}>
                    139
                    <br />
                    Homes
                </div>
            </div>
        </Link>
        <Link
            href="/search?location=32804&category=sale"
            as="/homesearch/for_sale/FL/Orlando/32804"
            className={css.item}
        >
            <div className={css.img}>
                <Image
                    src={collegeParkMap}
                    sizes=""
                    alt="Investworthy Neighborhoods - College Park, Orlando, FL"
                />
            </div>
            <div className={css.footer}>
                <div className={css.name}>
                    <div className={css.nameContent}>College Park</div>
                    <div className={css.state}>Orlando, FL</div>
                </div>
                <div className={css.listings}>
                    25
                    <br />
                    Homes
                </div>
            </div>
        </Link>
    </div>
);

export default Neighborhoods;
