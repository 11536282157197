import css from "../../css/index/featured-listings.module.css";

import React from "react";
import Listing from "../search/Listing";
import Link from "next/link";

const FeaturedListings = (props) => {
    const { listings, city, category } = props;

    return (
        <div className={css.container}>
            {listings.length > 0 && (
                <div className={css.listings}>
                    {listings.map((listing) => (
                        <Listing
                            listing={listing}
                            disableSlideshow={true}
                            key={listing.id}
                        />
                    ))}
                </div>
            )}

            <div className={css.buttonContainer}>
                <Link
                    href={`/search?location=${city.url}&category=${category}`}
                    as={`/homesearch/for_${category}/${city.url}`}
                    className={css.button}
                >
                    View all {category === "sale" ? "Listings" : "Rentals"} in{" "}
                    {city.name}
                </Link>
            </div>
        </div>
    );
};

export default FeaturedListings;
