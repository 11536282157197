import css from "../../css/index/search-form.module.css";
import autosuggestionCss from "../../css/index/autosuggestion.module.css";
import autosuggestionBlueCss from "../../css/index/autosuggestion-blue.module.css";

import Select, { components } from "react-select";
import AutocompleteLocation, {
    processLocationText,
} from "../autocomplete/location";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Router from "next/router";
import classNames from "classnames";
import { minPriceOptions, maxPriceOptions } from "../../service/search";

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div></div>
            </components.DropdownIndicator>
        )
    );
};

const customStyles = (category) => ({
    control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        boxShadow: state.isFocused
            ? category === "sale"
                ? "0 0 1px #fc8093"
                : "0 0 1px #82ceff"
            : "none",
        backgroundColor: "#f5f5f5",
        borderRadius: 0,
        height: 57,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: 17,
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: 14,
        color: "#303030",
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: 14,
        color: "#2c2c2c",
        fontWeight: "bold",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    dropdownIndicator: () => ({
        marginTop: "-8px",
        marginRight: 18,
        boxSizing: "border-box",
        height: "13px",
        width: "13px",
        borderStyle: "solid",
        borderColor: "#2c2c2c",
        borderWidth: "0px 1px 1px 0px",
        transform: "rotate(45deg)",
        transition: "border-width 150ms ease-in-out",
    }),
    menu: () => ({
        top: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 1,
        background: "#fafafa",
    }),
    menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        padding: 12,
        fontSize: 14,
        color: state.isFocused ? "#fff" : "#2c2c2c",
        backgroundColor: state.isFocused
            ? category === "sale"
                ? "#fc8093"
                : "#82ceff"
            : "none",
        "&:hover": {
            backgroundColor: category === "sale" ? "#fc8093" : "#82ceff",
            color: "#fff",
        },
    }),
});

const saleOptions = [
    { value: "sale", label: "For Sale" },
    { value: "rent", label: "For Rent" },
];

const minBedsOptions = [
    { value: 1, label: "1+" },
    { value: 2, label: "2+" },
    { value: 3, label: "3+" },
    { value: 4, label: "4+" },
    { value: 5, label: "5+" },
];

const minBathsOptions = [
    { value: 1, label: "1+" },
    { value: 2, label: "2+" },
    { value: 3, label: "3+" },
    { value: 4, label: "4+" },
    { value: 5, label: "5+" },
];

const SearchForm = (props) => {
    const [category, setCategory] = useState(
        props.category === "sale"
            ? { value: "sale", label: "For Sale" }
            : { value: "rent", label: "For Rent" }
    );

    const [location, setLocation] = useState(null);
    const [locationText, setLocationText] = useState(null);
    const [min_price, setMinPrice] = useState(null);
    const [max_price, setMaxPrice] = useState(null);
    const [min_beds, setMinBeds] = useState(null);
    const [min_baths, setMinBaths] = useState(null);
    const [pageCategory, setPageCategory] = useState(props.category);
    const [showSpinner, setShowSpinner] = useState(false);
    const [searchError, setSearchError] = useState(null);

    useEffect(() => {
        setPageCategory(props.category);

        setCategory(
            props.category === "sale"
                ? { value: "sale", label: "For Sale" }
                : { value: "rent", label: "For Rent" }
        );
    }, [props.category]);

    const handleChange = (name, value) => {
        if (name === "min_price") {
            value = value && value.value ? value : null;
            setMinPrice(value);
        } else if (name === "max_price") {
            value = value && value.value ? value : null;
            setMaxPrice(value);
        } else if (name === "min_beds") {
            setMinBeds(value);
        } else if (name === "min_baths") {
            setMinBaths(value);
        }
    };

    const handleCategoryChange = (value) => {
        setCategory(value);
        setMinPrice(null);
        setMaxPrice(null);
    };

    const handleLocationChange = (locationText, location) => {
        setLocationText(locationText);
        setLocation(location);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!locationText) {
            return;

        }

        setShowSpinner(true);

        const loc = await processLocationText(locationText.label);

        if (!loc) {
            if (loc === false) {
                setSearchError(
                    `Sorry, we could not find this area. Please check your address and try again`
                );
            }

            setShowSpinner(false);

            return;
        }

        // ZIP, CITY or STATE
        let query = {};

        ["min_price", "max_price", "min_beds", "min_baths"].map((filter) => {
            let value = null;

            if (filter === "min_price") {
                value = min_price;
            } else if (filter === "max_price") {
                value = max_price;
            } else if (filter === "min_beds") {
                value = min_beds;
            } else if (filter === "min_baths") {
                value = min_baths;
            }

            if (!value) {
                return null;
            }

            query[filter] = value.value;
        });

        const queryString = Object.keys(query)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(query[key])
                );
            })
            .join("&");

        query.location = loc.url;
        query.category = category.value;

        Router.push(
            {
                pathname: `/search`,
                query: query,
            },
            `/homesearch/for_${category.value}/${loc.url}` +
                (queryString ? `?${queryString}` : "")
        );

        setShowSpinner(false);
    };

    const formClassName = classNames({
        [css.form]: pageCategory === "sale",
        [css.formBlue]: pageCategory === "rent",
    });

    const isDisabledFilter = location && location.type === "listing";

    return (
        <>
            <div className={css.hiddenLabels}>
                <label htmlFor="select-sale-category">Category</label>
                <label htmlFor="select-sale-search">
                    Search zip, city, state
                </label>
                <label htmlFor="select-sale-min-price">Min Price</label>
                <label htmlFor="select-sale-max-price">Max Price</label>
                <label htmlFor="select-sale-min-beds">Min Beds</label>
                <label htmlFor="select-sale-min-baths">Min Baths</label>
            </div>

            <form className={formClassName} onSubmit={handleSubmit}>
                <Select
                    value={category}
                    components={{ DropdownIndicator }}
                    onChange={(value) => handleCategoryChange(value)}
                    options={saleOptions}
                    instanceId="select-sale-category"
                    placeholder="Category"
                    classNamePrefix="select-sale"
                    isSearchable={false}
                    styles={customStyles(pageCategory)}
                />

                <AutocompleteLocation
                    id="select-sale-search"
                    locationTypes="zip,city,state,listing"
                    placeholder="Search address, city, zip or state"
                    onChange={handleLocationChange}
                    showSpinner={showSpinner}
                    theme={
                        pageCategory === "sale"
                            ? autosuggestionCss
                            : autosuggestionBlueCss
                    }
                />
                {searchError && (
                    <div className={css.searchError}>{searchError}</div>
                )}

                <Select
                    value={min_price}
                    components={{ DropdownIndicator }}
                    onChange={(value) => handleChange("min_price", value)}
                    options={minPriceOptions(category.value, max_price)}
                    instanceId="select-sale-min-price"
                    placeholder="Min Price"
                    classNamePrefix="select-sale"
                    isSearchable={false}
                    styles={customStyles(pageCategory)}
                    isDisabled={isDisabledFilter}
                />

                <Select
                    value={max_price}
                    components={{ DropdownIndicator }}
                    onChange={(value) => handleChange("max_price", value)}
                    options={maxPriceOptions(category.value, min_price)}
                    instanceId="select-sale-max-price"
                    placeholder="Max Price"
                    classNamePrefix="select-sale"
                    isSearchable={false}
                    styles={customStyles(pageCategory)}
                    isDisabled={isDisabledFilter}
                />

                <Select
                    value={min_beds}
                    components={{ DropdownIndicator }}
                    onChange={(value) => handleChange("min_beds", value)}
                    options={minBedsOptions}
                    instanceId="select-sale-min-beds"
                    placeholder="Any Beds"
                    classNamePrefix="select-sale"
                    isSearchable={false}
                    styles={customStyles(pageCategory)}
                    isDisabled={isDisabledFilter}
                />

                <Select
                    value={min_baths}
                    components={{ DropdownIndicator }}
                    onChange={(value) => handleChange("min_baths", value)}
                    options={minBathsOptions}
                    instanceId="select-sale-min-baths"
                    placeholder="Any Baths"
                    classNamePrefix="select-sale"
                    isSearchable={false}
                    styles={customStyles(pageCategory)}
                    isDisabled={isDisabledFilter}
                />

                <button type="submit" className={css.submit}>
                    Search
                </button>
            </form>
        </>
    );
};

SearchForm.propTypes = {
    category: PropTypes.string,
};

export default SearchForm;
